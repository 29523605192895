<template>
  <v-container fluid class="py-6">
    <loading-overlay v-if="updating" :text="''" />
    <v-row>
      <v-col cols="12" class="position-relative">
        <v-card class="mb-30">
          <div class="card-header-padding card-border-bottom">
            <v-row>
              <v-col>
                <p class="font-weight-600 text-h3 text-typo mb-0">
                  Profil bearbeiten
                </p>
              </v-col>
            </v-row>
          </div>
          <v-card-text class="pa-0">
            <v-form>
              <h6
                class="text-caption text-uppercase font-weight-600 text-muted mb-8"
              >
                Nutzerinformation
              </h6>
              <div class="ps-lg-5">
                <v-row>
                  <v-col cols="12" md="6">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Nutzername</label
                    >
                    <v-text-field
                      hide-details
                      outlined
                      v-model="userInformations.username"
                      class="input-style font-size-input text-light-input placeholder-light"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >E-Mail-Adresse</label
                    >
                    <v-text-field
                      type="email"
                      hide-details
                      outlined
                      v-model="userInformations.email"
                      class="input-style font-size-input text-light-input placeholder-light"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Vorname</label
                    >
                    <v-text-field
                      hide-details
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light mb-5"
                      v-model="userInformations.firstname"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Nachname</label
                    >
                    <v-text-field
                      hide-details
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light mb-5"
                      v-model="userInformations.lastname"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-form>

            <div class="border-bottom my-5"></div>

            <h6
              class="text-caption text-uppercase font-weight-600 text-muted mb-8 mt-8"
            >
              Kontaktinformationen
            </h6>

            <div class="ps-lg-5">
              <v-row>
                <v-col cols="12">
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >Straße</label
                  >
                  <v-text-field
                    hide-details
                    outlined
                    class="input-style font-size-input text-light-input placeholder-light"
                    v-model="userInformations.street"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6" lg="4">
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >Postleitzahl</label
                  >
                  <v-text-field
                    type="number"
                    hide-details
                    outlined
                    class="input-style font-size-input text-light-input placeholder-light"
                    v-model="userInformations.zipcode"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="6" lg="4">
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >Ort</label
                  >
                  <v-text-field
                    hide-details
                    outlined
                    class="input-style font-size-input text-light-input placeholder-light"
                    v-model="userInformations.city"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="6" lg="4">
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >Land</label
                  >
                  <v-text-field
                    hide-details
                    outlined
                    class="input-style font-size-input text-light-input placeholder-light"
                    placeholder="Deutschland"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </div>

            <div class="border-bottom my-5"></div>

            <h6
              class="text-caption text-uppercase font-weight-600 text-muted mb-8 mt-8"
            >
              Firmen-/Rechnungsdaten
            </h6>

            <div class="ps-lg-5">
              <v-row>
                <v-col>
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >Firmenname</label
                  >
                  <v-text-field
                    hide-details
                    outlined
                    class="input-style font-size-input text-light-input placeholder-light"
                    v-model="userInformations.companyName"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >Website</label
                  >
                  <v-text-field
                    hide-details
                    outlined
                    class="input-style font-size-input text-light-input placeholder-light"
                    v-model="userInformations.website"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >Steuernummer</label
                  >
                  <v-text-field
                    hide-details
                    outlined
                    class="input-style font-size-input text-light-input placeholder-light"
                    v-model="userInformations.taxNumber"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >Umsatzsteuer-ID</label
                  >
                  <v-text-field
                    hide-details
                    outlined
                    class="input-style font-size-input text-light-input placeholder-light"
                    v-model="userInformations.vatNumber"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >Steueridentifikationsnummer</label
                  >
                  <v-text-field
                    required
                    hide-details
                    outlined
                    class="input-style font-size-input text-light-input placeholder-light"
                    v-model="userInformations.taxIdentificationNumber"
                    :rules="min11"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  !userInformations.vatNumber ||
                  userInformations.vatNumber.length < 6
                "
              >
                <v-col>
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >Umsatzsteuer Hinweis</label
                  >
                  <v-text-field
                    hint="Dieser Hinweis ist nur nötig, wenn keine Umsatzsteuer-ID angegen wird."
                    outlined
                    class="input-style font-size-input text-light-input placeholder-light"
                    v-model="userInformations.vatMessage"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >PayPal Client ID</label
                  >
                  <v-text-field
                    hide-details
                    outlined
                    :type="showClientID ? 'text' : 'password'"
                    class="input-style font-size-input text-light-input placeholder-light"
                    :append-icon="!showClientID ? 'fa-eye' : 'fa-eye-slash'"
                    @click:append="showClientID = !showClientID"
                    v-model="userInformations.client_id"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >PayPal Secret</label
                  >
                  <v-text-field
                    hide-details
                    outlined
                    :type="showSecret ? 'text' : 'password'"
                    class="input-style font-size-input text-light-input placeholder-light"
                    :append-icon="!showSecret ? 'fa-eye' : 'fa-eye-slash'"
                    @click:append="showSecret = !showSecret"
                    v-model="userInformations.client_secret"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >PayPal Modus</label
                  >
                  <v-select
                    :items="['sandbox', 'live']"
                    :hint="
                      userInformations.payment_mode == 'sandbox'
                        ? 'PayPal Testmodus. Zahlungen werden nur Simuliert!'
                        : 'PayPal Live Modus. Zahlungen werden real getätigt!'
                    "
                    persistent-hint
                    outlined
                    v-model="userInformations.payment_mode"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h6
                    class="text-danger text-caption text-uppercase font-weight-600 text-danger mb-8 mt-8"
                  >
                    Hinweis: Nur mit vollständigen Firmen-/Rechnungsangaben
                    können bezahlte Events angelegt werden, Achten Sie unbedingt
                    auf die Richtigkeit der Daten!
                  </h6>
                </v-col>
              </v-row>
            </div>

            <div class="border-bottom mb-10"></div>

            <v-row class="">
              <v-col>
                <v-row align="center" justify="space-around">
                  <v-btn
                    elevation="0"
                    :ripple="false"
                    height="43"
                    @click="updateUserData()"
                    class="font-weight-600 text-capitalize btn-neutral py-3 px-6 rounded-sm mb-5"
                    color="#fff"
                    >Profil speichern</v-btn
                  >
                  <v-btn
                    elevation="0"
                    :ripple="false"
                    height="43"
                    @click="resetUserData()"
                    class="font-weight-600 text-capitalize btn-neutral py-3 px-6 rounded-sm mb-5"
                    color="#fff"
                    >Änderungen zurücksetzen</v-btn
                  >
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <!-- <v-card class="h-100">
          <div class="py-4">
            <h6 class="text-uppercase text-body text-xs font-weight-bolder">
              Firmendaten:
            </h6>
            <v-list>
              <v-list-item-group class="border-radius-sm">
                <v-list-item
                  :ripple="false"
                  v-for="setting in accountSettings"
                  :key="setting.text"
                  class="pe-2 ps-0 border-radius-sm mb-3"
                >
                  <v-switch
                    :ripple="false"
                    class="d-inline-flex mt-0 pt-0 switch ms-auto"
                    v-model="setting.switchState"
                    hide-details
                  ></v-switch>
                  <v-list-item-content class="py-0">
                    <div class="ms-4 text-body text-sm">
                      {{ setting.text }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <h6
              class="text-uppercase text-body text-xs font-weight-bolder mt-4"
            >
              Rechnungsdaten:
            </h6>
            <v-list>
              <v-list-item-group class="border-radius-sm">
                <v-list-item
                  :ripple="false"
                  v-for="setting in applicationSettings"
                  :key="setting.text"
                  class="px-0 border-radius-sm mb-3"
                >
                  <v-switch
                    :ripple="false"
                    class="d-inline-flex mt-0 pt-0 switch ms-auto"
                    v-model="setting.switchState"
                    hide-details
                  ></v-switch>
                  <v-list-item-content class="py-0">
                    <div class="ms-4 text-body text-sm">
                      {{ setting.text }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
        </v-card> -->
        <hr class="vertical dark" />
      </v-col>
    </v-row>

    <v-snackbar
      top
      v-model="snackbar.visible"
      :color="snackbar.color"
      class="snackbar-shadow"
    >
      <div class="d-flex align-start alert-notify">
        <v-icon size="24" class="text-white me-3 mt-1 material-icons-round"
          >notifications</v-icon
        >
        <p class="mb-0">
          <span class="font-size-root font-weight-600">{{
            snackbar.title
          }}</span>
          <br />
          {{ snackbar.message }}
        </p>
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          elevation="0"
          max-width="136"
          :ripple="false"
          height="43"
          class="font-weight-600 text-capitalize py-3 px-6 rounded-sm"
          color="rgba(255,255,255, .85)"
          @click="snackbar.visible = false"
          v-bind="attrs"
        >
          <v-icon size="13">fas fa-times</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import coreData from "@/mixins/coreDataMixin";
// import Dropzone from "@/views/Components/Dropzone.vue";

export default {
  name: "Profile-Overview",
  mixins: [coreData],
  components: {
    // LoadingOverlay,
    // Dropzone
  },
  data: function () {
    return {
      min11: [(value) => (value && value.length == 11) || "11 Zeichen"],
      showSecret: false,
      showClientID: false,
      accountSettings: [
        {
          switchState: true,
          text: "Email me when someone follows me",
        },
        {
          switchState: false,
          text: "Email me when someone answers on...",
        },
        {
          switchState: true,
          text: "Email me when someone mentions me...",
        },
      ],
      applicationSettings: [
        {
          switchState: false,
          text: "New launches and projects",
        },
        {
          switchState: true,
          text: "Monthly product updates",
        },
        {
          switchState: false,
          text: "Subscribe to newsletter",
        },
      ],
      conversations: [
        {
          avatar: require("@/assets/img/kal-visuals-square.jpg"),
          user: "Sophie B.",
          message: "Hi! I need more information..",
        },
        {
          avatar: require("@/assets/img/marie.jpg"),
          user: "Anne Marie",
          message: "Awesome work, can you..",
        },
        {
          avatar: require("@/assets/img/ivana-square.jpg"),
          user: "Ivanna",
          message: "About files I can..",
        },
        {
          avatar: require("@/assets/img/team-4.jpg"),
          user: "Peterson",
          message: "Have a great afternoon..",
        },
        {
          avatar: require("@/assets/img/team-3.jpg"),
          user: "Nick Daniel",
          message: "Hi! I need more information..",
        },
      ],
      cards: [
        {
          image: require("@/assets/img/home-decor-1.jpg"),
          title: "Project #2",
          style: "Modern",
          description:
            "As Uber works through a huge amount of internal management turmoil.",
          avatars: [
            {
              image: require("@/assets/img/team-1.jpg"),
              name: "Elena Morison",
            },
            {
              image: require("@/assets/img/team-2.jpg"),
              name: "Ryan Milly",
            },
            {
              image: require("@/assets/img/team-3.jpg"),
              name: "Nick Daniel",
            },
            {
              image: require("@/assets/img/team-4.jpg"),
              name: "Peterson",
            },
          ],
        },
        {
          image: require("@/assets/img/home-decor-2.jpg"),
          title: "Project #1",
          style: "Scandinavian",
          description:
            "Music is something that every person has his or her own specific opinion about.",
          avatars: [
            {
              image: require("@/assets/img/team-3.jpg"),
              name: "Nick Daniel",
            },
            {
              image: require("@/assets/img/team-4.jpg"),
              name: "Peterson",
            },
            {
              image: require("@/assets/img/team-1.jpg"),
              name: "Elena Morison",
            },
            {
              image: require("@/assets/img/team-2.jpg"),
              name: "Ryan Milly",
            },
          ],
        },
        {
          image: require("@/assets/img/home-decor-3.jpg"),
          title: "Project #3",
          style: "Minimalist",
          description:
            "Different people have different taste, and various types of music.",
          avatars: [
            {
              image: require("@/assets/img/team-4.jpg"),
              name: "Peterson",
            },
            {
              image: require("@/assets/img/team-3.jpg"),
              name: "Nick Daniel",
            },
            {
              image: require("@/assets/img/team-2.jpg"),
              name: "Ryan Milly",
            },
            {
              image: require("@/assets/img/team-1.jpg"),
              name: "Elena Morison",
            },
          ],
        },
        {
          image: require("@/assets/img/home-decor-4.jpg"),
          title: "Project #4",
          style: "Gothic",
          description:
            "Why would anyone pick blue over pink? Pink is obviously a better color.",
          avatars: [
            {
              image: require("@/assets/img/team-4.jpg"),
              name: "Peterson",
            },
            {
              image: require("@/assets/img/team-3.jpg"),
              name: "Nick Daniel",
            },
            {
              image: require("@/assets/img/team-2.jpg"),
              name: "Ryan Milly",
            },
            {
              image: require("@/assets/img/team-1.jpg"),
              name: "Elena Morison",
            },
          ],
        },
      ],
    };
  },
  computed: {
    userInformations() {
      return this.$store.getters.userInformations;
    },
  },
  mounted() {
    this.$store.dispatch("tryAutoLogin");
  },
  methods: {
    updateUserData() {
      this.$store.dispatch("updateUserInformations", this.userInformations);
      this.SnackbarShow(
        "Success",
        "Aktion erfolgreich!",
        "Profil gespeichert!"
      );
    },
    resetUserData() {
      this.$store.dispatch("userInformations");
    },
  },
};
</script>
