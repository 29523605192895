<template>
  <v-row
    class="justify-center d-flex mt-n16 mx-0 bg-white position-relative shadow px-0"
  >
    <v-col cols="12" style="max-width: 1080px">
      <v-container fluid v-if="event">
        <v-row class="mb-6 mt-15" justify="center">
          <v-col
            v-for="(item, i) in [event]"
            :key="item.title + i"
            class="pt-0 mb-10 px-0"
          >
            <v-card class="border-radius-xl text-start">
              <div class="mb-10 card-header position-relative z-index-2">
                <div class="d-block blur-shadow-image">
                  <v-img
                    position="top center"
                    fill
                    contain
                    :src="getImage(item.img)"
                    class="img-fluid shadow border-radius-lg"
                    :alt="item.image"
                  />
                </div>
                <div
                  class="colored-shadow"
                  v-bind:style="{
                    backgroundImage: 'url(' + getImage(item.img) + ')',
                  }"
                ></div>
              </div>
              <div class="px-5 mt-0 mb-10">
                <v-row align="center" justify="center" class="h-100 mb-3">
                  <div>
                    <h3 class="font-weight-normal text-warning text-h3 px-4">
                      {{ item.date | moment("DD. MMMM YYYY") }}
                    </h3>
                  </div>
                </v-row>
                <v-row align="center" justify="center" class="h-100">
                  <div>
                    <h1
                      class="font-weight-normal text-black text-center text-h2 px-4"
                    >
                      {{ item.title }}
                    </h1>
                  </div>
                </v-row>
              </div>
              <div class="mb-5">
                <v-row>
                  <v-col cols="6" md="6" lg="3">
                    <v-card width="100%" color="#f6f8fa" class="px-5 py-8">
                      <v-row class="justify-center">
                        <div>
                          <v-icon size="35" class="text-warning"
                            >fas fa-calendar</v-icon
                          >
                        </div>
                      </v-row>
                      <div class="text-center mt-10 text-h4 mb-0">
                        {{ item.date | moment("DD. MMMM") }}
                      </div>
                    </v-card>
                  </v-col>
                  <v-col cols="6" md="6" lg="3">
                    <v-card width="100%" color="#f6f8fa" class="px-5 py-8">
                      <v-row class="justify-center">
                        <div>
                          <v-icon size="35" class="text-warning"
                            >fas fa-clock</v-icon
                          >
                        </div>
                      </v-row>
                      <div class="text-center mt-10 text-h4 mb-0">
                        {{ item.date | moment("HH:mm") }} Uhr
                      </div>
                    </v-card>
                  </v-col>
                  <v-col cols="6" md="6" lg="3">
                    <v-card width="100%" color="#f6f8fa" class="px-5 py-8">
                      <v-row class="justify-center">
                        <div>
                          <v-icon size="35" class="text-warning"
                            >fas fa-ticket</v-icon
                          >
                        </div>
                      </v-row>
                      <div
                        v-if="!item.hide_ticket_amount && calcSlots > 0"
                        class="text-center mt-10 text-h4 mb-0"
                      >
                        {{ calcSlots }} / {{ item.slots }}
                      </div>
                      <div
                        v-else
                        class="text-center mt-10 text-h4 mb-0"
                        :class="
                          calcSlots > 0
                            ? calcSlots > 20
                              ? 'text-success'
                              : 'text-warning'
                            : 'text-danger'
                        "
                      >
                        {{
                          calcSlots > 0
                            ? calcSlots > 20
                              ? "verfügbar"
                              : "geringe Anzahl"
                            : "ausgebucht"
                        }}
                      </div>
                    </v-card>
                  </v-col>
                  <v-col cols="6" md="6" lg="3">
                    <v-card width="100%" color="#f6f8fa" class="px-5 py-8">
                      <v-row class="justify-center">
                        <div>
                          <v-icon size="35" class="text-warning"
                            >fas fa-euro-sign</v-icon
                          >
                        </div>
                      </v-row>
                      <div class="text-center mt-10 text-h4 mb-0">
                        {{ item.price > 0 ? item.price + "€" : "Gratis" }}
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
                <v-btn
                  v-if="event.entries_active"
                  elevation="0"
                  block
                  height="43"
                  class="font-weight-bold text-uppercase btn-default mt-5"
                  :class="
                    bookable ? 'bg-gradient-warning' : 'bg-gradient-danger'
                  "
                  :disabled="!bookable"
                  color="#5e72e4"
                  small
                  @click="checkLoginState"
                  >{{ bookable ? "Jetzt Buchen" : "Leider ausgebucht!" }}</v-btn
                >
              </div>
              <div>
                <v-card width="100%" color="#f6f8fa" class="pa-5">
                  <div class="secondary">
                    <div class="mb-5">
                      <v-row dense>
                        <v-icon size="25" class="text-warning"
                          >fas fa-circle-info</v-icon
                        >
                        <div class="text-start mt-1 text-h4 mb-0 ml-2">
                          Veranstaltungsdetails
                        </div>
                      </v-row>
                    </div>

                    <span
                      class="mx-5 text-start text-typo"
                      v-html="item.description"
                    ></span>
                  </div>
                </v-card>
              </div>

              <div>
                <v-card width="100%" color="#f6f8fa" class="pa-5 mt-5">
                  <div class="text-h4">
                    {{ item.date | moment("DD.MM.YYYY HH:mm") }} Uhr
                  </div>
                  <div class="mt-4 text-h6">
                    {{ item.street + " " + item.zipcode + ", " + item.city }}
                  </div>
                  <div
                    class="mt-4 text-h6"
                    v-if="item.website && item.website != 'undefined'"
                  >
                    Veranstalter:
                    <a :href="item.website" target="_blank">{{
                      item.organizer
                    }}</a>
                  </div>
                  <div class="mt-4 text-subtitle-1" v-else>
                    Veranstalter: {{ item.organizer }}
                  </div>
                  <div class="mt-4 text-h6">
                    Eintrittspreis:
                    {{
                      item.price > 0
                        ? "Online: " + parseFloat(item.price).toFixed(2) + "€"
                        : "Gratis"
                    }}{{
                      item.price_lowered > 0
                        ? " • Ermäßigt: " +
                          parseFloat(item.price_lowered).toFixed(2) +
                          "€"
                        : ""
                    }}
                    {{
                      item.price_local > 0
                        ? " • Abendkasse: " +
                          parseFloat(item.price_local).toFixed(2) +
                          "€"
                        : ""
                    }}
                    {{
                      item.seat
                        ? " • Sitzplätze"
                        : " • Platzart siehe Beschreibung"
                    }}
                  </div>
                </v-card>
              </div>

              <div class="text-center mt-5">Verfügbare Karten:</div>

              <v-card-text>
                <v-row class="justify-center">
                  <v-chip-group column>
                    <v-chip>{{ calcSlots }} / {{ item.slots }}</v-chip>
                  </v-chip-group>
                </v-row>
              </v-card-text>

              <v-row
                class="mx-4 my-10"
                justify="space-around"
                v-if="event.entries_active"
              >
                <v-btn
                  elevation="0"
                  block
                  height="43"
                  class="font-weight-bold text-uppercase btn-default"
                  :class="
                    bookable ? 'bg-gradient-warning' : 'bg-gradient-danger'
                  "
                  :disabled="!bookable"
                  color="#5e72e4"
                  small
                  @click="checkLoginState"
                  >{{
                    bookable ? "Jetzt Buchen!" : "Leider ausgebucht!"
                  }}</v-btn
                >
              </v-row>
              <v-row class="mx-4 my-10" justify="space-around" v-else>
                <div class="text-subtitle-1 text-danger">
                  Die Online-Buchung ist aktuell nicht freigeschaltet für diese
                  Veranstaltung
                </div>
              </v-row>
              <div class="d-xs d-sm-none" v-if="isMobile">
                <v-col cols="12" class="text-center mx-auto">
                  <v-btn
                    :elevation="0"
                    @click="openEventLink()"
                    class="font-weight-bold text-xs btn-default bg-gradient-warning py-5 px-6 mt-2 mb-2 me-2 ms-auto"
                    color="primary"
                  >
                    Event in App anzeigen
                  </v-btn>
                </v-col>
              </div>
              <v-divider class="mx-4"></v-divider>
              <v-card-text>
                <div class="grey--text">
                  {{ item.manager.username }}
                </div>
                <p class="my-auto text-body font-weight-light">
                  {{ item.updated | moment("DD.MM.YYYY HH:mm") }} Uhr
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-card class="border-radius-xl mt-5">
          <v-card-text>
            <v-expansion-panels flat color="#ddd" class="mb-8">
              <v-expansion-panel class="bg-transparent">
                <v-expansion-panel-header class="border-bottom px-0">
                  <h5 class="text-h5 font-weight-bold text-body">
                    {{ eventComments.length }} Kommentare
                  </h5>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-if="eventComments.length == 0">
                    <h5 class="text-h5 text-typo font-weight-bold mt-0 mb-2">
                      Keine Kommentare vorhanden
                    </h5>
                  </div>
                  <div v-else>
                    <div class="d-flex mt-5">
                      <div class="flex-grow-1 ms-4">
                        <div
                          :key="index"
                          v-for="(comment, index) in eventComments"
                        >
                          <v-list-item
                            :ripple="false"
                            class="px-0 border-radius-sm mb-2"
                          >
                            <v-avatar
                              width="48"
                              height="48"
                              class="shadow border-radius-lg me-4"
                            >
                              <img
                                v-if="comment.user.avatar"
                                :src="getImage(comment.user.avatar)"
                                alt="Avatar"
                                class="border-radius-lg"
                              />
                              <div v-else>
                                {{ comment.user.username.charAt(0)
                                }}{{ comment.user.username.charAt(1) }}
                              </div>
                            </v-avatar>
                            <v-list-item-content>
                              <div class="d-flex align-center">
                                <div>
                                  <h6
                                    class="mb-0 text-sm text-sm text-typo font-weight-bold"
                                  >
                                    {{ comment.user.username }}
                                  </h6>
                                  <p
                                    class="mb-0 text-xs text-body font-weight-light"
                                  >
                                    {{ comment.comment }}
                                  </p>
                                </div>
                                <div class="ms-auto"></div>
                              </div>
                            </v-list-item-content>
                          </v-list-item>
                          <p class="text-xs mt-2 font-weight-light text-body">
                            {{ comment.created | moment("DD.MM.YYYY HH:mm") }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
        <h2
          class="mx-3 h1 font-weight-normal text-center mb-10 mt-10 text-body"
          style="font-size: 35px"
          v-if="events.length > 1"
        >
          Weitere Veranstaltungen
        </h2>
        <v-row class="mb-6 mt-15" justify="space-around">
          <v-col
            v-for="(item, i) in events.filter(
              (eventF) => eventF._id != event._id
            )"
            :key="item.title + i"
            lg="4"
            md="6"
            sm="12"
            cols="12"
            class="pt-0 mb-0"
          >
            <event-card :item="item"></event-card>
          </v-col>
        </v-row>
      </v-container>

      <v-container v-if="!event && !loadingEvents">
        <h2
          class="mx-3 h1 font-weight-normal text-center mb-10 mt-10 text-body"
          style="font-size: 35px"
        >
          Hoppla.... <br />
          Wir haben diese Veranstaltung nicht gefunden...
        </h2>
        <v-row justify="center">
          <div>
            <v-img
              height="100%"
              width="400px"
              :src="errorImage"
              class="img-fluid border-radius-lg"
              :alt="errorImage"
            />
          </div>
        </v-row>
      </v-container>
      <div class="pt-5">
        <appstores-teaser></appstores-teaser>
      </div>
      <sponsors></sponsors>

      <faq></faq>
    </v-col>
    <v-dialog v-model="orderView" width="700" v-if="event">
      <v-card elevation="0" class="">
        <v-toolbar elevation="0" class="px-5">
          <v-spacer></v-spacer>
          <v-toolbar-title>
            <span class="font-weight-bold">Ticket kaufen</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="orderView = false">
            <v-icon class="text-secondary">fas fa-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="px-8 pb-5" style="position: relative">
          <v-img
            class="mt-3 mt-sm-0"
            style="border-radius: 15px"
            cover
            height="250"
            :src="getImage(event.img)"
          ></v-img>
          <div
            class="border-radius-xl dark--text blur px-2 shadow-blur bg-dark theme--light"
            style="
              position: absolute;
              bottom: 35px;
              left: 45px;
              font-size: 25px;
            "
          >
            {{ event.date | moment("HH:mm") }} Uhr
          </div>
        </div>
        <div class="px-8 mb-4">
          <v-row class="ma-0 pa-0">
            <div style="width: 50px">
              <div class="text-h2 font-weight-bold" style="height: 40px">
                {{ event.date | moment("DD") }}
              </div>
              <div class="text-h5 font-weight-bold text-warning">
                {{ formatMonth }}
              </div>
            </div>

            <v-col class="pa-0 ma-0">
              <div class="pl-5 mt-2">
                <div class="text-h5 font-weight-bold">
                  {{ event.title }}
                </div>
                <!-- <div class="text-h6 font-weight-light">Tickets wählen</div> -->
              </div>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0 py-5" justify="center">
            <v-btn outlined color="deep-orange" @click="subAmount">
              <v-icon class="text-warning">fas fa-minus</v-icon>
            </v-btn>
            <div class="mx-5 text-h4 font-weight-bold text-warning">
              {{ orderData.amount }}
            </div>
            <v-btn outlined color="deep-orange" @click="addAmount">
              <v-icon class="text-warning">fas fa-plus</v-icon>
            </v-btn>
          </v-row>
        </div>
        <div
          class="text-center text-warning text-h5 my-5 px-5"
          v-if="event.price_lowered"
        >
          Ermäßigungen können im nächten Schritt unter "Personalisierung"
          gewählt werden.
        </div>
        <v-card-actions
          color="warning"
          class="px-8 py-4"
          style="background-color: orange"
        >
          <div class="text-h3 white--text">
            {{ orderData.amount }}
            {{ orderData.amount > 1 ? "Tickets" : "Ticket" }}
            <div class="text-h5 white--text">
              {{ calcTotal + " €" }}
              {{ event.vat_value > 0 ? "inkl. MwSt." : "" }}
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="text-h5 white--text"
            text
            @click="(orderSummary = true), (orderView = false)"
          >
            Weiter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="orderSummary" width="700" class="pa-0">
      <v-card color="white" elevation="0" class="" v-if="userInformations">
        <v-toolbar elevation="0" class="px-5">
          <v-btn icon @click="(orderSummary = false), (orderView = true)">
            <v-icon class="text-secondary">fas fa-chevron-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>
            <span class="font-weight-bold">Zusammenfassung</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="(orderSummary = false), (orderView = false)">
            <v-icon class="text-secondary">fas fa-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="px-5">
          <div class="mb-2 text-typo font-weight-bold">Event</div>
          <v-card
            color="#f6f8fa"
            style="border-radius: 20px !important"
            class="pa-6 pb-5"
          >
            <v-row class="justify-space-between text-typo">
              <v-col>
                <div>
                  <div class="text-h6 font-weight-light">
                    {{ event.title }}
                  </div>
                  <div class="text-h6 font-weight-light">
                    {{ event.date | moment("DD.MM.yyyy HH:mm") }} Uhr
                  </div>
                </div>
              </v-col>
              <v-col>
                <div>
                  <div style="position: relative">
                    <v-img
                      style="border-radius: 15px"
                      cover
                      class="mx-auto"
                      height="80"
                      width="120"
                      :src="getImage(event.img)"
                    ></v-img>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </div>
        <div class="px-5 mt-5">
          <div class="mb-2 text-typo font-weight-bold">Deine Bestellung</div>
          <v-card
            color="#f6f8fa"
            style="border-radius: 20px !important"
            class="pa-6 pb-5"
          >
            <v-row class="justify-space-between ma-0 pa-0">
              <div>
                <div class="body-1 mt-3 font-weight-bold text-typo">
                  Einzelpreis
                </div>
                <div class="body-2 text-typo">
                  {{ parseFloat(event.price).toFixed(2) + " €" }}
                  {{ event.vat_value > 0 ? "inkl. MwSt." : "" }}
                </div>
              </div>
              <div>
                <v-row class="ma-0 pa-0 py-5 text-typo" justify="center">
                  <v-btn
                    style="width: 50px !important"
                    outlined
                    class="px-0"
                    color="deep-orange"
                    @click="subAmount"
                  >
                    <v-icon class="px-0 text-warning">fas fa-minus</v-icon>
                  </v-btn>
                  <div class="mx-5 text-h4 font-weight-bold text-warning">
                    {{ orderData.amount }}
                  </div>
                  <v-btn outlined color="deep-orange" @click="addAmount">
                    <v-icon class="text-warning">fas fa-plus</v-icon>
                  </v-btn>
                </v-row>
              </div>
            </v-row>
            <v-row class="justify-space-between ma-0 pa-0" align="center">
              <div v-if="event.price_lowered">
                <div class="body-1 mt-3 font-weight-bold text-typo">
                  Ermäßigt
                </div>
                <div class="body-2 text-typo">
                  {{ parseFloat(event.price_lowered).toFixed(2) + " €" }}
                  {{ event.vat_value > 0 ? "inkl. MwSt." : "" }}
                </div>
              </div>
              <div v-if="event.price_lowered">
                <div>
                  <v-btn
                    @click="priceLoweredInfoDialog = true"
                    fab
                    dark
                    x-small
                    color="primary"
                  >
                    <i class="material-icons-round position-relative text-lg"
                      >info</i
                    >
                  </v-btn>
                </div>
              </div>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row class="justify-space-between ma-0 pa-0 text-typo">
              <div>
                <!-- <div>Gesamtsumme:</div>
                <div>{{ event.vat_value }}% Mwst.:</div> -->
                <div>
                  Gesamt
                  {{
                    event.vat_value ? "inkl. " + event.vat_value + "% Mwst" : ""
                  }}
                </div>
              </div>
              <div>
                <!-- <div>{{ calcTotal + " €" }}</div>
                <div>{{ calcTotal + " €" }}</div> -->
                <div>{{ calcTotal + " €" }}</div>
              </div>
            </v-row>
          </v-card>
        </div>
        <div class="px-5 mt-5">
          <v-row class="pa-0 ma-0" v-if="!event.personalization_required">
            <div class="mb-2 text-typo font-weight-bold">
              Tickets personalisieren
            </div>
            <div>
              <v-switch
                :ripple="false"
                class="d-inline-flex mt-0 pt-0 pl-5 switch ms-auto"
                v-model="personalizeTickets"
                hide-details
              ></v-switch>
            </div>
          </v-row>

          <v-card
            v-if="personalizeTickets || event.personalization_required"
            color="#f6f8fa"
            style="border-radius: 20px !important"
            class="px-6 pb-5"
          >
            <div>
              <div
                class="pt-5"
                :key="index"
                v-for="(participant, index) in orderData.participants"
              >
                <h5
                  class="body-1 font-weight-normal"
                  :class="
                    (!participant.name && event.personalization_required) ||
                    (participant.name.length < 3 &&
                      event.personalization_required)
                      ? 'text-danger'
                      : ' text-typo '
                  "
                >
                  Teilnehmer {{ index + 1 }}
                </h5>
                <v-row class="mt-0">
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      label="Vor- und Nachname"
                      color="#e91e63"
                      required
                      :rules="rules"
                      class="font-size-input text-typo input-style pt-2"
                      v-model="participant.name"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <div v-if="event.address_required">
                  <v-row class="mt-0">
                    <v-col cols="12">
                      <v-text-field
                        :label="
                          'Straße & Hausnummer' +
                          (event.address_required ? '' : ' (optional)')
                        "
                        color="#e91e63"
                        :required="event.address_required ? true : false"
                        :rules="event.address_required ? rules : []"
                        class="font-size-input input-style"
                        v-model="participant.street"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="mt-0">
                    <v-col cols="12" md="6" lg="4">
                      <v-text-field
                        id="zip"
                        type="number"
                        :label="
                          'Postleitzahl' +
                          (event.address_required ? '' : ' (optional)')
                        "
                        color="#e91e63"
                        :required="event.address_required ? true : false"
                        :rules="event.address_required ? rules : []"
                        class="font-size-input input-style"
                        v-model="participant.zipcode"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" md="6" lg="4">
                      <v-text-field
                        :label="
                          'Ort' + (event.address_required ? '' : ' (optional)')
                        "
                        color="#e91e63"
                        :required="event.address_required ? true : false"
                        :rules="event.address_required ? rules : []"
                        class="font-size-input input-style"
                        v-model="participant.city"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </div>
                <v-col
                  cols="12"
                  v-if="event.price_lowered && event.price_lowered != 0"
                >
                  <v-row
                    justify="start"
                    align="center"
                    class=""
                    v-if="event.price_lowered > 0"
                  >
                    <div>
                      <v-checkbox
                        v-model="participant.price_lowered"
                        label="Ermäßigt"
                      >
                      </v-checkbox>
                    </div>

                    <div>
                      <v-btn
                        class="mx-2 ml-5"
                        @click="priceLoweredInfoDialog = true"
                        fab
                        dark
                        x-small
                        color="primary"
                      >
                        <i
                          class="material-icons-round position-relative text-lg"
                          >info</i
                        >
                      </v-btn>
                    </div>
                  </v-row>
                </v-col>
              </div>
            </div>
          </v-card>
          <v-card
            v-else
            color="#f6f8fa"
            style="border-radius: 20px !important"
            class="pa-5"
          >
            <div class="text-typo body-1">
              Wir empfehlen eine Personalisierung der Tickets, um Verwechslungen
              beim Einlass und der Zuordnung zu vermeiden.
            </div>
            <div
              class="text-center text-warning text-h5 my-5 px-5"
              v-if="event.price_lowered"
            >
              Für eine Ermäßigung muss die Personalisierung aktiviert werden.
            </div>
            <v-btn
              @click="personalizeTickets = true"
              elevation="0"
              block
              height="43"
              class="font-weight-bold text-uppercase btn-default mt-5"
              :class="bookable ? 'bg-gradient-warning' : 'bg-gradient-danger'"
              :disabled="!bookable"
              color="deep-orange"
              small
              >Personalisierung aktivieren</v-btn
            >
          </v-card>
        </div>
        <div class="px-5 mt-5 text-typo">
          <div class="mb-2 text-typo font-weight-bold">
            Rechnungsinformationen
          </div>
          <v-card
            color="#f6f8fa"
            style="border-radius: 20px !important"
            class="pa-5 pb-5"
            v-if="profileCompleted"
          >
            <div class="text-h5 text-typo font-weight-bold">
              Bestellung auf "{{
                userInformations.firstname + " " + userInformations.lastname
              }}"
            </div>
            <div class="body-1 font-weight-bold text-typo">
              {{
                userInformations.street +
                ", " +
                userInformations.zipcode +
                " " +
                userInformations.city
              }}
            </div>
            <v-btn
              @click="profileCompleted = false"
              elevation="0"
              block
              height="43"
              class="font-weight-bold text-uppercase btn-default mt-5 bg-gradient-warning"
              color="deep-orange"
              small
              >Daten ändern</v-btn
            >
          </v-card>
          <v-card
            color="#f6f8fa"
            style="border-radius: 20px !important"
            class="pa-5 pb-5"
            v-else
          >
            <div class="px-5">
              <v-row class="text-center">
                <v-col cols="10" class="mx-auto">
                  <h5 class="text-h5 text-typo font-weight-normal mb-2">
                    Persönliche Daten
                  </h5>
                  <p class="text-body font-weight-light">
                    Wir verwenden Deine persönlichen Daten für die
                    Rechnungserstellung und Zuordnung der Tickets
                  </p>
                </v-col>
              </v-row>

              <v-row class="mt-0">
                <v-col cols="12">
                  <v-text-field
                    label="E-Mail-Adresse"
                    color="#e91e63"
                    disabled
                    required
                    :rules="rules"
                    class="font-size-input input-style"
                    v-model="userInformations.email"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row class="mt-0">
                <v-col cols="12">
                  <v-text-field
                    label="Firma (optional)"
                    color="#e91e63"
                    class="font-size-input input-style"
                    v-model="userInformations.companyName"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row class="mt-0">
                <v-col cols="12">
                  <v-text-field
                    label="Vorname"
                    color="#e91e63"
                    required
                    :rules="rules"
                    class="font-size-input input-style"
                    v-model="userInformations.firstname"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row class="mt-0">
                <v-col cols="12">
                  <v-text-field
                    label="Nachname"
                    color="#e91e63"
                    required
                    :rules="rules"
                    class="font-size-input input-style"
                    v-model="userInformations.lastname"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row class="mt-0">
                <v-col cols="12">
                  <v-text-field
                    label="Straße"
                    color="#e91e63"
                    required
                    :rules="rules"
                    class="font-size-input input-style"
                    v-model="userInformations.street"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row class="mt-0">
                <v-col cols="12" md="6" lg="4">
                  <v-text-field
                    id="zip"
                    type="number"
                    label="Postleitzahl"
                    color="#e91e63"
                    required
                    :rules="rules"
                    class="font-size-input input-style"
                    v-model="userInformations.zipcode"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="6" lg="4">
                  <v-text-field
                    label="Ort"
                    color="#e91e63"
                    required
                    :rules="rules"
                    class="font-size-input input-style"
                    v-model="userInformations.city"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </div>
            <v-btn
              @click="
                $store.dispatch('updateUserInformations', userInformations),
                  (profileCompleted = true)
              "
              elevation="0"
              block
              height="43"
              class="font-weight-bold text-uppercase btn-default mt-5"
              :class="
                userInformations &&
                (!userInformations.firstname ||
                  !userInformations.lastname ||
                  !userInformations.zipcode ||
                  !userInformations.street ||
                  !userInformations.city)
                  ? ''
                  : 'bg-gradient-warning'
              "
              :disabled="
                userInformations &&
                (!userInformations.firstname ||
                  !userInformations.lastname ||
                  !userInformations.zipcode ||
                  !userInformations.street ||
                  !userInformations.city)
              "
              color="deep-orange"
              small
              >Daten aktualisieren</v-btn
            >
          </v-card>
        </div>
        <div class="px-5 mt-5">
          <div class="mb-2 text-typo font-weight-bold">
            Wie möchtest du zahlen?
          </div>
          <v-row class="pa-0 ma-0">
            <v-sheet
              outlined
              color="deep-orange"
              style="border-radius: 20px !important; padding: 2px"
            >
              <v-card
                outlined
                color="#f6f8fa"
                style="border-radius: 18px !important"
                class="pa-6 pb-5"
              >
                <v-img
                  style="border-radius: 0px !important"
                  src="@/assets/img/logos/PayPal.svg.png"
                  alt="PayPal Logo"
                  max-width="120"
                  contain
                ></v-img>
                <!-- <div class="text-center text-h5 font-weight-bold text-typo">
                  PayPal
                </div> -->
              </v-card>
            </v-sheet>
          </v-row>
        </div>
        <div class="px-5 mt-5">
          <div class="text-center text-typo">
            <div>
              <v-checkbox v-model="gdprAccepted">
                <template v-slot:label>
                  <div>
                    Ich habe die
                    <span
                      class="text-warning"
                      @click="openNewWindow('/datenschutz')"
                    >
                      Datenschutzbestimmungen</span
                    >
                    sowie die
                    <span
                      class="text-warning"
                      @click="openNewWindow('/widerrufsbelehrung')"
                      >Widerrufsbelehrung</span
                    >
                    gelesen und verstanden.
                  </div>
                </template></v-checkbox
              >
            </div>
          </div>
          <div
            class="text-center text-typo"
            v-if="event.hints && event.hints != 'undefined'"
          >
            <div>
              <v-checkbox v-model="customTosAccepted">
                <template v-slot:label>
                  <div>
                    Ich habe die
                    <span
                      class="text-warning"
                      @click="
                        (customTosView = true), (customTosAccepted = true)
                      "
                    >
                      Veranstalterbedingungen</span
                    >
                    gelesen und akzeptiere diese.
                  </div>
                </template></v-checkbox
              >
            </div>
          </div>
          <div class="text-center text-typo font-weight-bold mt-2">
            Du wirst anschließend zu PayPal weiter geleitet um die Zahlung
            abzuschließen.
          </div>
        </div>
        <div class="px-5 pb-5">
          <v-btn
            v-if="event.entries_active && bookable"
            @click="submitOrder"
            elevation="0"
            block
            height="43"
            class="font-weight-bold btn-default mt-5"
            :class="
              !checkParticipants ||
              !gdprAccepted ||
              !profileCompleted ||
              (event.hints &&
                event.hints != 'undefined' &&
                event.hints != '' &&
                !customTosAccepted)
                ? ''
                : 'bg-gradient-warning '
            "
            :disabled="
              !checkParticipants ||
              !gdprAccepted ||
              !profileCompleted ||
              (event.hints &&
                event.hints != 'undefined' &&
                event.hints != '' &&
                !customTosAccepted)
            "
            color="deep-orange"
            small
          >
            <span class="text-h5" v-if="!updatingPayment">{{
              profileCompleted
                ? calcTotal + "€ " + "mit PayPal bezahlen"
                : "Persönliche Daten bestätigen"
            }}</span>
            <v-progress-circular
              v-else
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="customTosView" width="700">
      <v-card elevation="0" class="" v-if="userInformations">
        <v-toolbar elevation="0" class="px-5">
          <v-spacer></v-spacer>
          <v-toolbar-title>
            <span class="font-weight-bold">Veranstalter AGB und Hinweise</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="customTosView = false">
            <v-icon class="text-secondary">fas fa-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="pa-5">
          <span v-html="event.hints"></span>
          <div class="mt-5 text-danger">
            Für diese Veranstalter AGB und Hinweise ist ausschließlich der
            Veranstalter: {{ event.organizer }} verantwortlich.
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="orderCanceledDialog" width="700">
      <v-card elevation="0" class="">
        <v-toolbar elevation="0" class="px-5">
          <v-spacer></v-spacer>
          <v-toolbar-title>
            <span class="font-weight-bold">Bestellung abgebrochen</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="orderCanceledDialog = false">
            <v-icon class="text-secondary">fas fa-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="pa-5">
          <div class="text-h4 text-center text-danger">
            Ihre Bestellung wurde abgebrochen, Ihr Konto wurde nicht belastet.
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="priceLoweredInfoDialog"
      max-width="500px"
      v-if="event && event.price_lowered_info"
    >
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title
          class="pt-0 text-h5 text-typo font-weight-normal justify-center"
          >Ermäßigungshinweise</v-card-title
        >
        <v-row justify="center">
          <v-col cols="12" class="mt-5">
            <span v-html="event.price_lowered_info"></span>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" class="mb-5">
            <span
              >Für die Ermäßigung müssen Tickets personalisiert werden.</span
            >
          </v-col>
        </v-row>
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>

          <v-btn
            @click="priceLoweredInfoDialog = false"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-uppercase btn-default mt-5 bg-gradient-warning"
            color="deep-orange"
            >Ok</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import EventCard from "@/components/Widgets/EventCard.vue";
import Sponsors from "@/components/Widgets/Sponsors.vue";
import faq from "@/components/Widgets/FAQ.vue";
import moment from "moment";

export default {
  name: "EventDetails",
  components: {
    EventCard,
    Sponsors,
    faq,
  },
  computed: {
    checkParticipants() {
      if (this.event.personalization_required || this.personalizeTickets) {
        if (
          this.orderData.participants.find(
            (participant) => !participant.name || participant.name.length < 3
          )
        ) {
          return false;
        }
      }
      return true;
    },
    formatMonth() {
      return moment(this.event.date).format("MMMM").toUpperCase().slice(0, 3);
    },
    calcTotal() {
      let total = 0;
      this.orderData.participants.forEach((participant) => {
        if (participant.price_lowered) {
          total += this.event.price_lowered;
        } else {
          total += this.event.price;
        }
      });
      return total.toFixed(2).toString();
    },
    isMobile() {
      let userAgent = navigator.userAgent || navigator.vendor || window.opera;
      const isIpad = userAgent.match(/iPad/);
      const isAndroid = userAgent.match(/Android/);
      const isIphone = userAgent.match(/iPhone/);
      return isIpad || isAndroid || isIphone;
    },
    // calcTotalNetto() {
    //   let total = this.event.price * this.orderData.amount;
    //   return total.toFixed(2);
    // },
    // calcTotalVAT() {
    //   let total = this.event.price * this.orderData.amount;
    //   total = total;
    //   return total.toFixed(2);
    // },
    calcSlots() {
      try {
        return this.event.slots - this.event.entries.length <= 0
          ? 0
          : this.event.slots - this.event.entries.length;
      } catch (error) {
        return 0;
      }
    },
    bookable() {
      if (this.calcSlots > 0) {
        return true;
      } else {
        // {
        //   {
        //     item.price > 0
        //       ? "Online: " + parseFloat(item.price).toFixed(2) + "€"
        //       : "Gratis";
        //   }
        // }
        // {
        //   {
        //     item.price_lowered > 0
        //       ? " • Erm.: " + parseFloat(item.price_lowered).toFixed(2) + "€"
        //       : "";
        //   }
        // }
        // {
        //   {
        //     item.price_local > 0
        //       ? " • Abendk.: " + parseFloat(item.price_local) + "€"
        //       : "";
        //   }
        // }
        return false;
      }
    },
    loadingEvents() {
      return this.$store.getters.loadingEvents;
    },
    event() {
      return this.$store.getters.event;
    },
    events() {
      return this.$store.getters.events;
    },
    eventComments() {
      return this.$store.getters.eventComments;
    },
    userInformations() {
      return this.$store.getters.userInformations;
    },
    paymentReady() {
      return this.$store.getters.paymentReady;
    },
    paymentLink() {
      return this.$store.getters.paymentLink;
    },
    updatingPayment() {
      return this.$store.getters.updatingPayment;
    },
  },
  watch: {
    $route: function (to, from) {
      console.log("changed route:", to, from);
      this.getData();
    },
    userInformations() {
      if (
        this.userInformations &&
        (!this.userInformations.firstname ||
          !this.userInformations.lastname ||
          !this.userInformations.zipcode ||
          !this.userInformations.city ||
          !this.userInformations.street)
      ) {
        this.profileCompleted = false;
      } else {
        this.profileCompleted = true;
      }
    },
    paymentLink() {
      if (this.paymentLink) {
        window.open(this.paymentLink, "_self").focus();
      }
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$store.dispatch("getEventByID", this.$route.params.id);
      this.$store.dispatch("getEvents");
      let userAgent = navigator.userAgent || navigator.vendor || window.opera;
      this.isIpad = userAgent.match(/iPad/);
      this.isAndroid = userAgent.match(/Android/);
      this.isIphone = userAgent.match(/iPhone/);
      if (this.$route.query.canceled) {
        this.orderCanceledDialog = true;
      }
      if (
        this.userInformations &&
        (!this.userInformations.firstname ||
          !this.userInformations.lastname ||
          !this.userInformations.zipcode ||
          !this.userInformations.city ||
          !this.userInformations.street)
      ) {
        this.profileCompleted = false;
      } else {
        this.profileCompleted = true;
      }
    },
    checkLoginState() {
      if (!this.bookable) return;
      if (this.userInformations) {
        if (this.userInformations.emailVerified) {
          this.orderView = true;
        } else {
          this.$store.commit("accountCreated", true);
          this.$store.commit("popRegister", true);
        }
      } else {
        this.$store.commit("popLogin", true);
      }
    },
    openNewWindow(link) {
      window.open(link, "_blank").focus();
    },
    submitOrder() {
      //   console.log(this.orderData);
      var paymentInfo = {
        transactions: [
          {
            item_list: {
              items: [],
            },
            amount: {
              currency: "EUR",
              total: this.calcTotal,
            },
            description: this.event.title,
          },
        ],
      };
      this.orderData.participants.forEach((participant) => {
        paymentInfo.transactions[0].item_list.items.push({
          name:
            this.event.title +
            " - " +
            participant.name +
            (participant.price_lowered ? " (ermäßigt)" : ""),
          sku: this.event._id,
          price: participant.price_lowered
            ? this.event.price_lowered.toFixed(2).toString()
            : this.event.price.toFixed(2).toString(),
          tax: participant.price_lowered
            ? (this.event.price_lowered - this.event.price_lowered / 1.19)
                .toFixed(2)
                .toString()
            : (this.event.price - this.event.price / 1.19)
                .toFixed(2)
                .toString(),
          currency: "EUR",
          quantity: 1,
        });
      });

      let eventEntry = {
        event: this.event._id,
        firstname: this.userInformations.firstname,
        lastname: this.userInformations.lastname,
        street: this.userInformations.street,
        email: this.userInformations.email,
        zip: this.userInformations.zipcode,
        city: this.userInformations.city,
        participants: this.orderData.participants,
      };

      let payload = {
        paymentInfo: paymentInfo,
        eventEntry: eventEntry,
        successUrl: "https://oelsnitz-ticket.de/tickets/" + this.event._id,
        cancelUrl:
          "https://oelsnitz-ticket.de/events/" +
          this.event._id +
          "?canceled=true",
      };
      if (this.event.price > 0) {
        console.log(payload);
        this.$store.dispatch("postPayment", payload);
      } else {
        this.$store.dispatch("registerForEvent", payload.eventEntry);
      }
    },
    addAmount() {
      if (
        this.orderData.amount < this.event.slots - this.event.entries.length &&
        this.orderData.amount < 10
      ) {
        this.orderData.amount += 1;
        this.orderData.participants.push({
          name: "",
          street: "",
          city: "",
          zipcode: "",
        });
      }
    },
    subAmount() {
      if (this.orderData.amount >= 1 && this.orderData.amount > 1) {
        this.orderData.amount -= 1;
        this.orderData.participants.splice(
          this.orderData.participants.length - 1,
          1
        );
      }
    },
    openEventLink() {
      let userAgent = navigator.userAgent || navigator.vendor || window.opera;
      console.log(userAgent);
      const isIpad = userAgent.match(/iPad/);
      const isAndroid = userAgent.match(/Android/);
      const isIphone = userAgent.match(/iPhone/);

      if (isAndroid || isIphone || isIpad) {
        try {
          window.location.replace(`oelsnitz-ticket://events/${this.event._id}`);
        } catch (error) {
          alert(error);
          console.log(error);
        }
      }
    },
    getImage(link) {
      return process.env.VUE_APP_API_BASEURL + "/" + link;
    },
  },
  data() {
    return {
      priceLoweredInfoDialog: false,
      gdprAccepted: false,
      rules: [
        (value) => !!value || "Erforderlich.",
        (value) => (value && value.length >= 3) || "Min 3 characters",
      ],
      customTosView: false,
      orderCanceledDialog: false,
      customTosAccepted: false,
      personalizeTickets: false,
      profileCompleted: false,
      orderData: {
        amount: 1,
        userInformations: {},
        participants: [
          {
            name: "",
            street: "",
            city: "",
            zipcode: "",
          },
        ],
      },
      orderView: false,
      orderSummary: false,
      isIpad: null,
      isAndroid: null,
      isIphone: null,
      tab: "",
      errorImage: require("@/assets/img/undraw/undraw_Page_not_found_re_e9o6.png"),
    };
  },
};
</script>
<style>
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.v-dialog {
  margin: 10px !important;
  border-radius: 20px !important;
}
</style>